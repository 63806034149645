import React, { useState, useContext, ChangeEvent } from "react";
import {
  useTheme,
  TextField,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { AuthContext } from "../../context/auth-context/AuthContext";
import useMemberDetails from "../../hooks/members/useMemberDetails";
import useProjects from "../../hooks/projects/useProjects";
import { Link } from "react-router-dom";
import { AddCircleOutline, Clear } from "@mui/icons-material";
import { doesPermissionsHaveSomeOf } from "../../utils/authUtils";
import FieldSection, { Field } from "../../components/general/FieldSection";

/**
 * Page for creating a new company
 */
const CreateCompany: React.FunctionComponent = () => {
  const theme = useTheme();

  /**
   * Function which provides the styles of the CreateCompany
   */
  const styles = {
    button: {
      marginTop: "20px",
      marginBottom: "20px",
      border: "0",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      "&:hover": {
        color: "black",
      },
    },
  };

  const { auth } = useContext(AuthContext);
  const hasCrmPermissions = doesPermissionsHaveSomeOf(auth.permissions, [13]);
  const { memberDetails } = useMemberDetails(auth.userID!);
  const [createdCompany, setCreatedCompany] = useState<string>("");
  const [openDialog, setOpenDialog] = useState(false);
  const { allIndustries } = useProjects();

  const [nameField, setNameField] = useState<string>("");
  const [industryField, setIndustryField] = useState<string>("");
  const [descriptionField, setDescriptionField] = useState<string>("");
  const [addressAdditionalField, setAddressAdditionalField] = useState<string>("");
  const [streetField, setStreetField] = useState<string>("");
  const [postalCodeField, setPostalCodeField] = useState<string>("");
  const [cityField, setCityField] = useState<string>("");
  const [urlField, setUrlField] = useState<string>("");
  const [informationField, setInformationField] = useState<string>("");
  const [checkedClassified, setCheckedClassified] = useState(false);
  const [contactPartnerField, setContactPartnerField] = useState<string[]>([""]);
  const [createdAt, setCreatedAt] = useState<number | null>(null);

  const [isNameFieldEmpty, setIsNameFieldEmpty] = useState<boolean>(false);
  const [isIndustryFieldEmpty, setIsIndustryFieldEmpty] = useState<boolean>(false);
  const [isContactPartnerFieldEmpty, setIsContactPartnerFieldEmpty] = useState<boolean>(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  /**
   * Handles the checkbox checking
   */
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setCheckedClassified(checked);
  };

  /**
   * Handles the industry selection
   */
  const handleIndustryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIndustryField(event.target.value);
  };

  /**
   * Function which enables the proper adding of contact partners
   */
  const handleAddContactPartner = () => {
    setContactPartnerField([...contactPartnerField, ""]);
  };

  /**
   * Functions which enables the proper removing of contact partners
   */
  const handleRemoveContactPartner = (index: number) => {
    setContactPartnerField(contactPartnerField.filter((_, i) => i !== index));
  };

  /**
   * Ensures the updating of the contact partners
   */
  const handleContactPartnerChange = (index: number, value: string) => {
    const updatedFields = [...contactPartnerField];
    updatedFields[index] = value;
    setContactPartnerField(updatedFields);
  };

  const industryDescriptions = Array.from(new Set(allIndustries.map((industry) => industry.description.toString())));

  /**
   * Submits the inputted company data if critical fields are not empty.
   */
  const handleSubmit = () => {
    if (
      nameField.trim() === "" ||
      industryField.trim() === "" ||
      contactPartnerField.every((field) => field.trim() === "")
    ) {
      if (nameField.trim() === "") {
        setIsNameFieldEmpty(true);
      } else {
        setIsNameFieldEmpty(false);
      }
      if (industryField.trim() === "") {
        setIsIndustryFieldEmpty(true);
      } else {
        setIsIndustryFieldEmpty(false);
      }
      if (contactPartnerField.every((field) => field.trim() === "")) {
        setIsContactPartnerFieldEmpty(true);
      } else {
        setIsContactPartnerFieldEmpty(false);
      }
      return;
    }
    setIsNameFieldEmpty(false);
    setIsIndustryFieldEmpty(false);
    setIsContactPartnerFieldEmpty(false);
    const companyContent = `
Erstellt von: ${memberDetails?.firstname} ${memberDetails?.lastname}
Erstellt am: ${createdAt}
Name: ${nameField}
Branche: ${industryField}
Kurzbeschreibung: ${descriptionField}
Adresszusatz: ${addressAdditionalField}
Straße/Nr: ${streetField}
PLZ: ${postalCodeField}
Ort: ${cityField}
Webseite: ${urlField}
Wichtige Informationen: ${informationField}
Geheim: ${checkedClassified}
Ansprechpartner: ${contactPartnerField.join(", ")}
    `;
    setCreatedCompany(companyContent);
    setOpenDialog(true);
  };

  const fields: Array<Field> = [
    {
      label: "Name",
      state: nameField,
      width: "full",
      onChangeCallback: (e) => setNameField(e.target.value),
      type: "Text",
      helperText: isNameFieldEmpty ? "Bitte gib einen Namen ein!" : undefined,
      error: isNameFieldEmpty,
    },
    {
      label: "Branche",
      state: industryField,
      width: "full",
      onChangeCallback: handleIndustryChange,
      type: "Dropdown",
      values: industryDescriptions.map((description) => ({
        value: description,
        label: description,
      })),
      helperText: isIndustryFieldEmpty ? "Bitte gib eine Adresse ein!" : undefined,
      error: isIndustryFieldEmpty,
    },
    {
      label: "Kurzbeschreibung",
      state: descriptionField,
      width: "full",
      onChangeCallback: (e) => setDescriptionField(e.target.value),
      type: "TextBig",
      rows: 4,
    },
    {
      label: "Adresszusatz",
      state: addressAdditionalField,
      width: "full",
      onChangeCallback: (e) => setAddressAdditionalField(e.target.value),
      type: "Text",
    },
    {
      label: "Straße/Nr",
      state: streetField,
      width: "full",
      onChangeCallback: (e) => setStreetField(e.target.value),
      type: "Text",
    },
    {
      label: "PLZ",
      state: postalCodeField,
      width: "full",
      onChangeCallback: (e) => setPostalCodeField(e.target.value),
      type: "Text",
    },
    {
      label: "Ort",
      state: cityField,
      width: "full",
      onChangeCallback: (e) => setCityField(e.target.value),
      type: "Text",
    },
    {
      label: "Webseite",
      state: urlField,
      width: "full",
      onChangeCallback: (e) => setUrlField(e.target.value),
      type: "Text",
    },
    {
      label: "Wichtige Informationen",
      state: informationField,
      width: "full",
      onChangeCallback: (e) => setInformationField(e.target.value),
      type: "TextBig",
      rows: 4,
    },
    {
      label: "Geheim",
      state: checkedClassified,
      width: "full",
      onChangeCallback: handleCheckboxChange,
      type: "Checkbox",
    },
  ];

  return (
    <div>
      {hasCrmPermissions && (
        <Grid container spacing={1} sx={{ paddingTop: "30px", paddingLeft: "10px" }}>
          <Grid item>
            <Button sx={styles.button} variant="contained" component={Link} to={`/unternehmen/`}>
              Zurück
            </Button>
          </Grid>
          <Grid item>
            <FieldSection title={"Neuer Kunde"} fields={fields}></FieldSection>
          </Grid>
          {contactPartnerField.map((field, index) => (
            <Grid item container alignItems="center" spacing={2} xs={12} key={index}>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  label="Ansprechpartner"
                  fullWidth
                  value={field}
                  onChange={(e) => handleContactPartnerChange(index, e.target.value)}
                />
                {index === 0 && isContactPartnerFieldEmpty && (
                  <Typography color="error">{"Es muss mindestens einen Ansprechpartner je Kunden geben"}</Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={() => handleRemoveContactPartner(index)}
                  disabled={contactPartnerField.length === 1}
                >
                  <Clear />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <IconButton aria-label="add" color="primary" onClick={handleAddContactPartner}>
              <AddCircleOutline />
            </IconButton>
          </Grid>
          <Grid item>
            <Button
              sx={styles.button}
              variant="contained"
              onClick={() => {
                handleSubmit();
                setCreatedAt(Date.now());
              }}
            >
              Kunden erstellen
            </Button>
          </Grid>
        </Grid>
      )}
      <Dialog open={openDialog} onClose={() => handleDialogClose()}>
        <DialogTitle>Bestätigung</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Folgender Kunde wird erstellt:`}
            <div dangerouslySetInnerHTML={{ __html: createdCompany.replace(/\n/g, "<br />") }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose()} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => handleDialogClose()} color="primary">
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateCompany;
